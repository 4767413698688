define([], function() {
  class ScrollToFirstError {
    init(element) {
      const errorElementDesktop = element.querySelector('.alert-danger');
      const errorElementMobile = element.querySelector('.alert-error');

      if (errorElementDesktop) {
        errorElementDesktop.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest'});
      } else if (errorElementMobile) {
        errorElementMobile.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
      }
    }
  }

  return ScrollToFirstError;
});
